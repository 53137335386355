import { animate, query, style, transition, trigger } from '@angular/animations';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { ButtonComponent } from '@sb/ui';

import { IconComponent } from '../../+authenticated/shared/icon.component';
import { SbSpinnerComponent } from '../sb-spinner.component';

type State = 'loading' | 'empty' | 'content';

type StateSize = 'extra-small' | 'medium-small' | 'small';
type StateDirection = 'column' | 'row';

const duration = '176ms';

@Component({
  selector: 'content-state',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div [@loadingAnimation]="state">
      <div
        [ngClass]="[
          'content-state__' + size,
          'content-state__' + direction,
          stateClass ? stateClass : '',
          !showIcon ? 'content-state__no-icon' : ''
        ]"
        class="content-state"
        *ngIf="isLoading || isEmpty"
      >
        <div *ngIf="isLoading" class="content-state__loading">
          <sb-spinner [isWhite]="isWhite"></sb-spinner>
          <span *ngIf="loadingText">{{ loadingText }}</span>
        </div>
        <div *ngIf="isEmpty && !isLoading" class="content-state__empty">
          <icon *ngIf="showIcon" class="content-state--icon" [svgIcon]="icon"></icon>
          <div *ngIf="title || (content && showContent)" class="content-state--details">
            <h1 *ngIf="title" class="content-state--title">{{ title }}</h1>
            <h3 *ngIf="content && showContent" class="content-state--content">{{ content }}</h3>
          </div>
          <button
            *ngIf="btnText && !isLoading"
            class="content-state--button"
            sb-button
            [color]="'primary'"
            (click)="handleClick()"
          >
            {{ btnText }}
          </button>
        </div>
      </div>
      <div *ngIf="!isLoading && !isEmpty" class="content-state__loaded">
        <ng-content></ng-content>
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
    </div>
  `,
  animations: [
    trigger('loadingAnimation', [
      transition('* => loading', [
        query('.content-state__loading', [style({ opacity: 0 }), animate(duration, style({ opacity: 1 }))]),
      ]),
      transition('* => empty', [
        query('.content-state__empty', [style({ opacity: 0 }), animate(duration, style({ opacity: 1 }))]),
      ]),
      transition('* => content', [
        query('.content-state__loaded', [style({ opacity: 0 }), animate(duration, style({ opacity: 1 }))]),
      ]),
    ]),
  ],
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, NgClass, SbSpinnerComponent, IconComponent, ButtonComponent],
})
export class ContentStateComponent implements OnChanges {
  @Input()
  public icon = 'leave-5';
  // TODO I'd much rather not set a default value for the icon and use that to determine if the icon should be shown or not
  // but a lot of the current composing components rely on this default right now. Given how we want to get rid of content-state
  // sooner or later anyway, I'll for now just introduce this new property to control the icon visibility
  @Input()
  public showIcon = true;

  @Input()
  public loadingText: string;

  @Input()
  public title: string;
  @Input()
  public content: string;
  @Input()
  public btnText?: string;

  @Input()
  public showContent = true;

  @Input()
  public isWhite?: boolean = false;

  @Input()
  public stateClass?: string;
  @Input()
  public direction: StateDirection = 'column';
  @Input()
  public size?: StateSize = 'small';

  @Input()
  public isLoading = false;
  @Input()
  public isEmpty = false;

  @Input()
  public contentTemplate: TemplateRef<any>;

  @Output()
  public onClick?: EventEmitter<any> = new EventEmitter<any>();

  public state: State = 'content';

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['isLoading'] || changes['isEmpty']) {
      this.state = this.calculateState();
    }
  }

  public handleClick() {
    this.onClick.emit();
  }

  private calculateState(): State {
    if (this.isLoading) {
      return 'loading';
    }

    if (this.isEmpty) {
      return 'empty';
    }

    return 'content';
  }
}
